import { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import {
  doc,
  onSnapshot,
  collection,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useToast,
  Text,
  IconButton,
  Button,
  Center,
  Checkbox,
  Box,
  Stack,
  Link,
  // Image,
  Heading,
  HStack,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Modal,
  useDisclosure,
  TableContainer,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  Thead,
  Badge,
} from '@chakra-ui/react';
import Select from 'react-select';
import { ExternalLinkIcon, CloseIcon } from '@chakra-ui/icons';
import { BsChevronRight } from 'react-icons/bs';
import { FaCheckCircle, FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { MdCall } from 'react-icons/md';

import {
  db,
  PRICING_OPTIONS,
  LISTING,
  LINK,
  masterSafetyPromotionEnabled,
} from '../constants';
import SpinnerPage from './SpinnerPage';
import BuyAddonModal from './BuyAddonModal';
import DataContext from '../context/DataContext';
import currencyFormat from '../services/currencyFormat';
import currencyFormatFr from '../services/currencyFormatFr';
import numberFormat from '../services/numberFormat';
import numberFormatFr from '../services/numberFormatFr';
import notifySafetySelected from '../services/notifySafetySelected';
import { ReactComponent as PlansImage } from '../assets/svg/illustration-select.svg';
import OrDivider from '../components/OrDivider';
import CreditLogos from '../components/CreditLogos';

function WarrantyPage(props) {
  const { i18n, t } = useTranslation('global');
  let french = i18n.language === 'fr' ? true : false;

  const [listing, setListing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promoSelected, setPromoSelected] = useState(false);
  const [upgradesVisible, setUpgradesVisible] = useState(false);
  const [addonCode, setAddonCode] = useState();
  const [refPartner, setRefPartner] = useState();
  const [allPartners, setAllPartners] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenInfo,
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
  } = useDisclosure();
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const dataContext = useContext(DataContext);
  const { userData } = dataContext;
  const province = listing?.province;
  // Redirect if listing is not user's or if not vehicle

  const fetchRefPartner = async partnerSlug => {
    setLoading(true);
    // console.log('fetching');
    try {
      // get all partners
      const colRef3 = collection(db, 'partners');
      const docsSnap3 = await getDocs(colRef3);

      const allPartnersToSave = [];

      docsSnap3.forEach(partner => {
        if (!partner.data()) return;
        if (!partner.data().approved) return;
        // do not show name or logo if influencer
        if (partner.data().type === 'influencer') return;

        const partnerToSave = {
          businessName: partner.data().businessName,
          label:
            partner.data().businessName +
            ' (' +
            partner.data().address.city +
            ')',
          refSlug: partner.data().refSlug,
          value: partner.data().refSlug,
          // type: partner.data().type,
          address1: `${partner.data().address.civic} ${
            partner.data().address.street
          } ${
            partner.data().address?.apt
              ? 'Suite: ' + partner.data().address.apt
              : ''
          }`,
          address2: `${partner.data().address.city} ${
            partner.data().address.province
          }`,
          address3: partner.data().address.postal,
          place_id: partner.data().address.place_id,
          formatted_address: partner.data().address.formatted_address,
          photoURL: partner.data()?.photoURL ? partner.data()?.photoURL : '',
          businessPhone: partner.data()?.businessPhone
            ? partner.data()?.businessPhone
            : '',
        };
        allPartnersToSave.push(partnerToSave);

        // if refslug matches a partner, display
        if (partnerToSave.refSlug === partnerSlug) {
          setRefPartner(partnerToSave);
        }
      });
      setAllPartners(allPartnersToSave);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (listing.garageRef) {
      fetchRefPartner(listing.garageRef);
    }
  }, [listing.garageRef]);

  useEffect(() => {
    // only seller or buyer can access
    if (listing && listing.sellerUid !== auth.currentUser.uid) {
      if (listing?.buyerUid !== auth.currentUser.uid) {
        toast({
          title: t('cannotEditListing'),
          status: 'error',
          isClosable: true,
        });
        navigate('/');
      }
    }
  }, [auth, listing, navigate, toast, t]);

  useEffect(() => {
    // get and listen to this document
    const unsubscribe = onSnapshot(
      doc(db, 'listings', params.listingUid),
      doc => {
        if (!doc.exists()) {
          navigate('/');
          toast({
            title: t('listingDoesNotExist'),
            status: 'error',
            isClosable: true,
          });
          return;
        }
        // set to state
        setListing(doc.data());
        if (doc.data().warrantyAddonCode) {
          setAddonCode(doc.data().warrantyAddonCode);
        }
      }
    );
    return unsubscribe;
  }, [navigate, toast, params, t]);

  const selectPromoWarranty = async () => {
    setLoading(true);
    const docRef = doc(db, 'listings', listing.uid);
    await setDoc(
      docRef,
      {
        warrantyName: 'Powertrain 90-Days',
        warrantyPending: true,
        warrantySafetyPromo: true,
        warrantyActive: false,
      },
      { merge: true }
    );
    setLoading(false);
    notifySafetySelected();
  };

  const selectGarage = async value => {
    setLoading(true);
    setRefPartner(value);
    const docRef = doc(db, 'listings', listing.uid);
    await setDoc(
      docRef,
      {
        garageRef: value.refSlug,
      },
      { merge: true }
    );
    setLoading(false);
  };

  const clearGarage = async () => {
    if (
      !window.confirm(t('Clear selected Service Partner and select again?'))
    ) {
      return;
    }

    setLoading(true);
    const docRef = doc(db, 'listings', listing.uid);
    await setDoc(
      docRef,
      {
        garageRef: '',
      },
      { merge: true }
    );
    setRefPartner();
    setLoading(false);
  };

  if (!listing || loading) {
    return <SpinnerPage />;
  }

  const renderPlanInfo = () => {
    return (
      <>
        <Center>
          <Text
            mt={3}
            textAlign="center"
            fontSize="2xl"
            lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('powertrain')}
          </Text>
        </Center>
        <Center>
          <Text fontSize="lg">vs</Text>
        </Center>
        <Center align="center" mb={3}>
          <Text
            //  mt={3}
            textAlign="center"
            fontSize="2xl"
            lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('comprehensive')}
          </Text>
        </Center>
        <TableContainer>
          <Table
            variant="simple"
            // w='100vw'
            layout="fixed"
          >
            <Thead>
              <Tr>
                <Th p={1} borderWidth={0} borderColor="black"></Th>
                <Th
                  p={1}
                  w="10%"
                  borderWidth={1}
                  borderColor="black"
                  textAlign="center"
                >
                  {t('powertrainShort')}
                </Th>
                <Th
                  p={1}
                  w="10%"
                  borderWidth={1}
                  borderColor="black"
                  textAlign="center"
                >
                  {t('comprehensiveShort')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature1')}
                </Td>
                <Td p={1} borderWidth={1} borderColor="black">
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature2')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature1')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature3')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>
              <Tr>
                <Td p={1} pl={3} borderColor="black" borderBottomWidth={1}>
                  <Text as="i" fontWeight="bold">
                    {t('coverage')}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature4')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature5')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature6')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature7')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature8')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature9')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature10')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature2')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature3')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature4')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature5')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature6')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature7')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature8')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature9')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Text my={2} mt={5} textAlign="center">
          <a
            href={LINK.warrantyPlansUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('moreInfo')}
            <ExternalLinkIcon ml="5px" />
          </a>
        </Text>
      </>
    );
  };
  const priceBuyButtons = () => {
    return (
      <>
        {(addonCode && addonCode !== 'carfax' && addonCode !== 'BASE90') ? (
          <>
            <HStack align={'center'} justify={'center'}>
              {!french && <Text fontSize={'xl'}>$</Text>}
              <Text fontSize={'3xl'} fontWeight={600} mx={1}>
                {!french
                  ? numberFormat(
                      PRICING_OPTIONS.addons[addonCode][province]
                        ?.priceBeforeTax
                    )
                  : numberFormatFr(
                      PRICING_OPTIONS.addons[addonCode][province]
                        ?.priceBeforeTax
                    )}
              </Text>
              {french && <Text fontSize={'xl'}>$</Text>}
            </HStack>
            <Text align="center" fontSize="lg">
              {/* {'+ '}
              {!french && '$'}
              {numberFormat(
                PRICING_OPTIONS.addons[addonCode][province]?.taxes?.gst
                )}
              {french && ' $'}

              {PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst === 0 ? (
                ' HST'
              ) : (
                <>
                  {' GST + ' +
                    currencyFormat(
                      PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst
                    ) +
                    ' QST'}
                </>
              )} */}

              {`+ ${
                !french
                  ? currencyFormat(
                      PRICING_OPTIONS.addons[addonCode][province]?.taxes?.gst
                    )
                  : currencyFormatFr(
                      PRICING_OPTIONS.addons[addonCode][province]?.taxes?.gst
                    )
              } ${t('gst')}`}
              {PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst !== 0 &&
                ` + ${
                  !french
                    ? currencyFormat(
                        PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst
                      )
                    : currencyFormatFr(
                        PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst
                      )
                } ${t('qst')}`}
            </Text>
            <HStack align={'center'} justify={'center'}>
              {!french && <Text fontSize={'xl'}>$</Text>}
              <Text fontSize={'2xl'} mx={1}>
                {!french
                  ? numberFormat(
                      PRICING_OPTIONS.addons[addonCode][province]
                        ?.priceIncludingTax
                    )
                  : numberFormatFr(
                      PRICING_OPTIONS.addons[addonCode][province]
                        ?.priceIncludingTax
                    )}
              </Text>
              {french && <Text fontSize={'xl'}>$</Text>}
            </HStack>
            <Text textAlign="center">{t('includingTax')}</Text>

            {!props.hideBank && (
              <>
                <Button
                  isDisabled={!userData.bankAccountAdded}
                  w="full"
                  colorScheme="primary"
                  mt={4}
                  onClick={onOpen}
                >
                  {t('buyFromBankAccount')}
                </Button>
                {!userData.bankAccountAdded && (
                  <>
                    <Center mt={2}>
                      <Text textAlign="center">
                        {t('cannotUseBankAccountNotConnected')}
                      </Text>
                    </Center>
                    <Center mb={4}>
                      <Button
                        colorScheme="primary"
                        variant="link"
                        mx={2}
                        // my={5}
                        onClick={() => navigate('/wallet')}
                        rightIcon={<BsChevronRight />}
                      >
                        {t('visitTheWalletPagetoEnable')}
                      </Button>
                    </Center>
                  </>
                )}
                <OrDivider my={2} />
              </>
            )}

            <Button
              w="full"
              colorScheme="primary"
              onClick={() =>
                window.open(
                  `${
                    PRICING_OPTIONS?.addons[addonCode]?.[province].checkoutUrl
                  }?prefilled_email=${userData.email.replace(
                    /\+/g,
                    '%2B'
                  )}&client_reference_id=${userData.uid}WARRANTY${listing.uid}`,
                  '_blank'
                )
              }
              mb={2}
            >
              {t('creditCardOrInstallmentPlan')}
              <ExternalLinkIcon ml="5px" />
            </Button>
            <Center>
              <Text textAlign="center">
                {t('payInUpTo24MonthlyInstallments')}
              </Text>
            </Center>
            <CreditLogos />
            <br />
            <Center align="center" mt={1}>
              <Text>
                After activation, we may require a no charge inspection at a
                location of your choosing
              </Text>
            </Center>

            {/* <Center mt={2}>
              <Button
                variant="link"
                colorScheme="primary"
                // w='full'
                // size="sm"
                // as="u"
                leftIcon={<InfoOutlineIcon />}
                onClick={onOpenInfo}
                mb={3}
              >
                {t('viewEEZEPlanDetails')}
              </Button>
            </Center> */}
            <br />
            <br />
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </>
    );
  };
  const planSelector = (filteredArray, upgrade) => {
    // warranty upgrade view
    if (upgrade) {
      return (
        <>
          <Box minWidth="330px" pb={2} px={2} mt={10}>
            <Center align="center">
              <Text>
                You may upgrade or extend your plan at a discounted rate
              </Text>
            </Center>

            {!upgradesVisible ? (
              <>
                <Button
                  w="full"
                  mb={20}
                  mt={5}
                  variant="link"
                  colorScheme="primary"
                  onClick={() => setUpgradesVisible(true)}
                >
                  View Upgrade Options
                </Button>
              </>
            ) : (
              <>
                <Stack direction="row" justify="space-between" my={3}>
                  <IconButton
                    variant="ghost"
                    size={'xs'}
                    color="grey"
                    // icon={<CloseIcon />}
                  />
                  <Text
                    // size='md'
                    fontWeight="bold"
                    textAlign="center"
                    // color="primary.500"
                  >
                    {/* {!upgrade
                      ? t('selectAWarrantyCapital')
                      : 'SELECT AN UPGRADE'} */}
                    {/* {t('selectAnUpgradeCapital')} */}
                    SELECT AN UPGRADE
                  </Text>
                  <IconButton
                    variant="ghost"
                    size={'xs'}
                    color="grey"
                    icon={addonCode ? <CloseIcon /> : null}
                    onClick={() => {
                      setAddonCode();
                      setUpgradesVisible(false);
                    }}
                  />
                </Stack>

                <Stack direction="column">
                  {filteredArray.map(plan => (
                    <HStack
                      key={plan.code}
                      onClick={e => setAddonCode(plan.code)}
                      borderColor={
                        plan.code === addonCode ? 'primary.500' : 'gray.400'
                      }
                      borderRadius={'lg'}
                      p={1}
                      borderWidth={plan.code === addonCode ? 2 : 1}
                    >
                      <Checkbox
                        colorScheme="primary"
                        isChecked={plan.code === addonCode}
                      />
                      <Stack direction="row" justify="space-between" w="100%">
                        <Box>
                          <Text fontSize="lg" fontWeight="bold">
                            {french ? plan.nameFr : plan.name}
                          </Text>
                          <Text fontSize="xs">
                            {french ? plan.feature1Fr : plan.feature1}
                          </Text>
                          <Text fontSize="xs">
                            {french ? plan.feature2Fr : plan.feature2}
                          </Text>
                          {/* <Text fontSize="xs">
                      {french ? plan.feature3Fr : plan.feature3}
                    </Text> */}
                        </Box>
                        <Box textAlign="center">
                          <Text fontWeight="bold" fontSize="md">
                            {french ? plan.termFr : plan.term}
                          </Text>
                          <Text fontWeight="bold" fontSize="lg">
                            {french ? plan.price1Fr : plan.price1}
                          </Text>
                          <Text>{french ? plan.price2Fr : plan.price2}</Text>
                        </Box>
                      </Stack>
                    </HStack>
                  ))}
                </Stack>
              </>
            )}
          </Box>
          {/* {!addonCode && (
          <Center mt={2}>
            <Button
              variant="link"
              colorScheme="primary"
              // w='full'
              // size="sm"
              // as="u"
              leftIcon={<InfoOutlineIcon />}
              onClick={onOpenInfo}
              mb={3}
            >
              {t('viewEEZEPlanDetails')}
            </Button>
          </Center>
        )} */}
        </>
      );
    }
    // non upgrade view regular buy options
    return (
      <>
        <Box minWidth="330px" pb={2} px={2}>
          <Stack direction="row" justify="space-between" my={3}>
            <IconButton
              variant="ghost"
              size={'xs'}
              color="grey"
              // icon={<CloseIcon />}
              // for spacing reasons
            />
            <Text
              // size='md'
              fontWeight="bold"
              textAlign="center"
              // color="primary.500"
            >
              {t('selectAWarrantyCapital')}
              {/* : t('selectAnUpgradeCapital')} */}
            </Text>
            <IconButton
              variant="ghost"
              size={'xs'}
              color="grey"
              icon={addonCode || promoSelected ? <CloseIcon /> : null}
              onClick={() => {
                setAddonCode();
                setUpgradesVisible(false);
                setPromoSelected(false);
              }}
            />
          </Stack>
          {masterSafetyPromotionEnabled && (
            <HStack
              mb={2}
              onClick={() => {
                setPromoSelected(!promoSelected);
                setAddonCode();
              }}
              borderColor={promoSelected ? 'primary.500' : 'gray.400'}
              borderRadius={'lg'}
              p={1}
              borderWidth={promoSelected ? 2 : 1}
              style={{ zIndex: 99999 }}
            >
              {/* checkbox not selecting here */}
              {promoSelected ? (
                <FaCheckSquare color="purple" />
              ) : (
                <FaRegSquare color="lightgray" />
              )}
              <Stack direction="row" justify="space-between" w="100%">
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    Powertrain
                    {/* {french ? plan.nameFr : plan.name} */}
                  </Text>
                  {/* <Badge
                      rounded="full"
                      px="2"
                      fontSize="xs"
                      colorScheme="red"
                      // bgColor='red'
                      // color='white'
                    >
                      Limited Time Offer
                    </Badge> */}
                  {/* <Text fontSize="xs">
                    $0 ded
                    </Text> */}
                  {/* <Text fontSize="xs">
                      coverage2
                    </Text> */}
                  {/* <Text fontSize="xs">
                  {french ? plan.feature3Fr : plan.feature3}
                </Text> */}
                  <Text fontWeight="bold" fontSize="md">
                    FREE with Every Safety Inspection
                  </Text>
                </Box>
                <Box
                  textAlign="center"
                  // w='35%'
                >
                  <Text fontWeight="bold" fontSize="md">
                    90-Day
                  </Text>
                  <Text fontWeight="bold" fontSize="xl">
                    $0
                  </Text>
                  <Badge
                    rounded="full"
                    px="2"
                    fontSize="xs"
                    colorScheme="red"
                    // bgColor='red'
                    // color='white'
                  >
                    Special Offer
                  </Badge>
                </Box>
              </Stack>
            </HStack>
          )}

          <Stack direction="column">
            {filteredArray.map(plan => (
              <HStack
                key={plan.code}
                onClick={e => {
                  setAddonCode(plan.code);
                  setPromoSelected(false);
                }}
                borderColor={
                  plan.code === addonCode ? 'primary.500' : 'gray.400'
                }
                borderRadius={'lg'}
                p={1}
                borderWidth={plan.code === addonCode ? 2 : 1}
              >
                <Checkbox
                  colorScheme="primary"
                  isChecked={plan.code === addonCode}
                />
                <Stack direction="row" justify="space-between" w="100%">
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      {french ? plan.nameFr : plan.name}
                    </Text>
                    <Text fontSize="xs">
                      {french ? plan.feature1Fr : plan.feature1}
                    </Text>
                    <Text fontSize="xs">
                      {french ? plan.feature2Fr : plan.feature2}
                    </Text>
                    {/* <Text fontSize="xs">
                  {french ? plan.feature3Fr : plan.feature3}
                </Text> */}
                  </Box>
                  <Box textAlign="center">
                    <Text fontWeight="bold" fontSize="md">
                      {french ? plan.termFr : plan.term}
                    </Text>
                    <Text fontWeight="bold" fontSize="lg">
                      {french ? plan.price1Fr : plan.price1}
                    </Text>
                    <Text>{french ? plan.price2Fr : plan.price2}</Text>
                  </Box>
                </Stack>
              </HStack>
            ))}
          </Stack>

          {promoSelected && (
            <Button
              mt={2}
              colorScheme="primary"
              size="lg"
              w="full"
              onClick={selectPromoWarranty}
            >
              Claim Your Free Warranty
            </Button>
          )}
        </Box>
        {/* {!addonCode && (
        <Center mt={2}>
          <Button
            variant="link"
            colorScheme="primary"
            // w='full'
            // size="sm"
            // as="u"
            leftIcon={<InfoOutlineIcon />}
            onClick={onOpenInfo}
            mb={3}
          >
            {t('viewEEZEPlanDetails')}
          </Button>
        </Center>
      )} */}
      </>
    );
  };
  const warrantyBuyOptions = () => {
    // get warranties from PRICING_OPTIONS.addons
    const allAddonsArray = Object.values(PRICING_OPTIONS.addons);
    // get only WARRANTY
    // upgrade:true if the vehicle already has an EEZE plan
    // console.log(allAddonsArray);
    const filteredArray = allAddonsArray.filter(
      obj => obj.type === 'WARRANTY' && obj?.upgrade !== true
    );
    // console.log(filteredArray);
    return (
      <>
        {planSelector(filteredArray)}

        {priceBuyButtons()}
      </>
    );
  };
  const warrantyUpgradeOptions = () => {
    // get warranties from PRICING_OPTIONS.addons
    const allAddonsArray = Object.values(PRICING_OPTIONS.addons);
    // get only WARRANTY
    // upgrade:true if the vehicle already has an EEZE plan
    // console.log(allAddonsArray);
    const filteredArray = allAddonsArray.filter(
      obj => obj.type === 'WARRANTY' && obj?.upgrade === true
    );

    // console.log(filteredArray);

    return (
      <>
        {planSelector(filteredArray, true)}

        {priceBuyButtons()}
      </>
    );
  };
  const warrantyNotActive = () => {
    return (
      <Center align="center">
        <Text
          textAlign="center"
          // color="primary.500"
          mb={5}
          as="b"
        >
          {t('warrantyIsBeingProcessed')}
        </Text>
      </Center>
    );
  };
  const promoWarrantyNotActive = () => {
    // to display after vehicle created for safety test 90 day warranty promo
    return (
      <Box mb={10}>
        <Center align="center">
          {!listing?.partnerSafetyTest ? (
            <Text
              textAlign="center"
              // color="primary.500"
              mb={5}
              as="b"
            >
              You may notify any EEZE-authorized partner to upload the safety
              inspection to activate your FREE 90-day Powertrain Warranty
            </Text>
          ) : (
            <>
              <Text
                textAlign="center"
                // color="primary.500"
                mb={5}
                as="b"
              >
                Congratulations!
                <br />
                <br />
                The partner has uploaded the safety inspection, we are
                processing your FREE 90-day Powertrain warranty. You will
                receive an email confirmation within two business days
              </Text>
            </>
          )}
        </Center>

        {listing.garageRef ? (
          <>
            <Center>
              <Text align="center" as="i">
                Selected Partner
              </Text>
            </Center>

            <Center mb={2}>
              <Heading fontSize="xl" align="center">
                {refPartner?.businessName}
              </Heading>
            </Center>

            <Center align="center">
              <Text>{refPartner?.address1}</Text>
            </Center>
            <Center align="center">
              <Text>
                {refPartner?.address2} {refPartner?.address3}
              </Text>
            </Center>
            {/* <Center align="center">
              <Text>{refPartner?.address3}</Text>
            </Center> */}
            {/* <Center align="center">
              <Text mr={4}>{refPartner?.businessPhone}</Text>
            </Center> */}
            <HStack mt={1}>
              <Center w="50%">
                <Box
                // w="50%"
                >
                  <a href={`tel:${refPartner?.businessPhone}`}>
                    <Button
                      leftIcon={<MdCall />}
                      colorScheme="primary"
                      // mt={2}
                      size="sm"
                      w="full"
                      // variant="outline"
                    >
                      {/* Call */}
                      {refPartner?.businessPhone}
                    </Button>
                  </a>
                </Box>
              </Center>

              {refPartner?.formatted_address && (
                <Center w="50%">
                  <Button
                    colorScheme="primary"
                    size="sm"
                    rightIcon={<ExternalLinkIcon />}
                    // mt={2}
                    // variant="outline"
                    // w="50%"
                    onClick={() =>
                      window.open('https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(refPartner.businessName+' '+refPartner.formatted_address),
                        '_blank'
                      )
                    }
                    // onClick={() =>
                    //   window.open(
                    //     'https://www.google.com/maps/place/?q=place_id:' +
                    //       refPartner.place_id,
                    //     '_blank'
                    //   )
                    // }
                  >
                    Get Directions
                  </Button>
                </Center>
              )}
            </HStack>
            {refPartner?.formatted_address && (
              <>
                {/* <br />
                EXPERIMENTING WITH GOOGLE MAPS LINK THAT WAS NOT OPENING ON MOBILE BROWSER
                <br />
                <Link
                  href={
                    'https://www.google.com/maps/place/?q=query_place_id:'+encodeURIComponent(refPartner.place_id)
                  }
                  isExternal
                >
                  query_place_id: {refPartner?.place_id} <ExternalLinkIcon mx="2px" />
                </Link>
                <br/>
                <br/>
                <Link
                  href={
                    'https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(refPartner.businessName+' '+refPartner.formatted_address)
                  }
                  isExternal
                >
                REVISED add plus name <ExternalLinkIcon mx="2px" />
                </Link> */}
                {/* <Link
                  href={
                    'https://www.google.com/maps/place/?q=place_id:'+encodeURIComponent(refPartner.formatted_address)
                  }
                  isExternal
                >
                  {refPartner?.formatted_address} <ExternalLinkIcon mx="2px" />
                </Link> */}
                {/* <Button textAlign="center" colorScheme="primary" size="sm">
                <a
                  href={
                    'https://www.google.com/maps/place/?q=place_id:' +refPartner.place_id
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white' }}
                  >
                  Get Directions
                  <ExternalLinkIcon ml="5px" />
                </a>
              </Button> */}
              </>
            )}

            {!listing.partnerSafetyTest && (
              <Button
                mt={10}
                variant="link"
                w="full"
                size="sm"
                onClick={clearGarage}
              >
                Change
              </Button>
            )}
            <Button
              onClick={() => navigate('/')}
              w="full"
              colorScheme="primary"
              variant="outline"
              mt={20}
            >
              Back to Home
            </Button>
          </>
        ) : (
          <>
            {!allPartners ? (
              <Center>
                <Button onClick={fetchRefPartner} my={10} colorScheme="primary">
                  Find an EEZE-Authorized Partner
                </Button>
              </Center>
            ) : (
              <Box mb={4}>
                <Select
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderColor: 'inherit',
                      borderRadius: '10px',
                      height: '3rem',
                    }),
                  }}
                  value={refPartner}
                  placeholder={t('selectAnEEZEPartner')}
                  onChange={selectGarage}
                  options={allPartners}
                  isClearable={true}
                  isSearchable={true}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };
  const renderWarrantyScreen = () => {
    // if no warrantyName
    if (!listing?.warrantyName) {
      // and if no warrantyPending
      if (!listing.warrantyPending) {
        // render initial view, normal buy options
        return (
          <>
            {!props.hideSplash && (
              <>
                <Center>
                  <PlansImage width={'40%'} />
                </Center>
                <Text align="center">
                  {t('driveWorryFreeChoosePlan')}

                  <Text
                    as="span"
                    p={2}
                    fontWeight="bold"
                    onClick={onOpenInfo}
                    color="primary.500"
                  >
                    {' '}
                    {t('viewEEZEPlanDetails')}
                  </Text>
                </Text>
              </>
            )}
            {warrantyBuyOptions()}
          </>
        );
      } else {
        // else if warrantyPending, user just paid for it with stripe
        // but we don't have the warrantyName yet
        // show view thanks, processing
        return (
          <>
            <Center mb={5}>
              <PlansImage width={'50%'} />
            </Center>

            <Center align="center" mb={5}>
              <Text>Thank you for your payment!</Text>
            </Center>

            {warrantyNotActive()}
          </>
        );
      }
    } else {
      // else warrantyName exists
      if (!listing?.warrantyActive) {
        // and if still not active, no warrantyActive
        // show view we are processing, upgrade options
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                {t('selectedWarranty')}
              </Text>
            </Center>
            <Center mb={5}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>
            {/* if just created vehicle for 90 day promo, display custom text */}
            {listing?.warrantyName.includes('90') && listing.warrantySafetyPromo
              ? promoWarrantyNotActive()
              : warrantyNotActive()}

            {warrantyUpgradeOptions()}
          </>
        );
      } else {
        // else if warrantyActive
        // show view active with upgrade options
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                {t('activeWarranty')}
              </Text>
            </Center>
            <Center mb={1}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>

            {listing.warrantyEndDate && (
              <Center mb={5}>
                <Text align="center">
                  Coverage until {listing.warrantyEndDate}
                </Text>
              </Center>
            )}
            {warrantyUpgradeOptions()}
          </>
        );
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <BuyAddonModal
            addon={PRICING_OPTIONS.addons[addonCode]}
            listingUid={listing.uid}
            province={province}
          />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={() => {
                onClose();
              }}
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenInfo} onClose={onCloseInfo}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {renderPlanInfo()}
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={() => {
                onCloseInfo();
                // setAddonCode();
              }}
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*   a buyer canot see the warranties tab until status completed */}
      {listing.buyerUid === userData.uid &&
      listing.status !== LISTING.STATUS.COMPLETED ? (
        <Text my={10} textAlign="center">
          {t('onlyTheSellerCanActivateWarrantyPlansBeforeCompletion')}
        </Text>
      ) : (
        <>{renderWarrantyScreen()}</>
      )}
    </>
  );
}

export default WarrantyPage;
