import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Box,
  Container,
  AlertIcon,
  Alert,
  AlertDescription,
  AlertTitle,
  Center,
  Flex,
  Image,
  Select,
  Spinner,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
// import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import vinlocation from '../assets/vinlocation.png';
import {
  db,
  isDev,
  allProvinces,
  LISTING,
  eligibleWarrantyProvinces,
} from '../constants';
import CARS from '../services/carInfo';
import decodeVIN from '../services/decodeVIN';
import deleteListing from '../services/deleteListing';
import AppBody from '../components/AppBody';
import WarrantyPage from '../components/WarrantyPage';
import SpinnerPage from '../components/SpinnerPage';
import Header from '../components/Header';
import DataContext from '../context/DataContext';
// import OrDivider from '../components/OrDivider';
// import sendEmailToAdmin from '../services/sendEmailToAdmin';

function EditListingWarranty(props) {
  const { t } = useTranslation('global');
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [decoding, setDecoding] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const dataContext = useContext(DataContext);
  const { userData } = dataContext;

  const [queryParameters] = useSearchParams();
  const [listing, setListing] = useState(props.listing);
  const [formData, setFormData] = useState({
    ...props.listing,
  });
  // const auth = getAuth();
  // const params = useParams();

  useEffect(() => {
    // change tab
    if (queryParameters.get('t')) {
      const index = queryParameters.get('t');
      setTabIndex(Number(index));
    }
  }, [queryParameters]);

  useEffect(() => {
    // real time listener in EditListing that feeds props
    // update when changes
    setFormData(props.listing);
    setListing(props.listing);
    setLoading(false);
  }, [props.listing]);

  const navigate = useNavigate();

  const onClickSave = (newFormData, nextTab) => {
    setIsSavingForm(true); // to disable buttons
    setIsEditing(false); // to prevent new entries

    const formDataToSave = newFormData ? newFormData : formData;
    // console.log('formDataToSave', formDataToSave)
    // set form data to firestore

    const docRef = doc(db, 'listings', listing.uid);
    setDoc(docRef, formDataToSave, { merge: true })
      .then(() => {
        // console.log('Document Field has been updated successfully');
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true);
        if (nextTab) setTabIndex(tabIndex + 1);
      })
      .catch(error => {
        // console.log(error);
        toast({
          description: t('listingUpdateError'),
          status: 'error',
        });
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true); // back to editing
      });
  };

  const handleTabsChange = index => {
    onClickSave(formData);
    if (!index) {
      // if 0, change to 1
      // if 1, change to 0
      if (tabIndex === 0) {
        return setTabIndex(1);
      } else if (tabIndex === 1) {
        return setTabIndex(0);
      }
    }
    setTabIndex(index);
  };

  const onClickDelete = async () => {
    if (listing.buyerUid) {
      toast({
        title: t('cannotDeleteAListingLinkedToAnotherUser'),
        description: t('considerCancellingInstead'),
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    const response = await deleteListing(listing);
    // console.log('delete response', response)
    if (response.success) {
      toast({
        title: t('listingDeleted'),
        isClosable: true,
      });
      navigate('/');
    } else {
      setLoading(false);
      toast({
        title: t('notDeleted'),
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onClickCancel = () => {
    if (listing !== formData) {
      // are you sure
      if (!window.confirm(t('discardChangesAreYouSure'))) {
        return;
      }
    }

    setFormData(listing);
  };

  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    // if (e.target.id === 'askingPrice') {
    //   // value = value.toFixed(2); limit to 2 decimals TODO:
    // }
    // if (e.target.id === 'odometer') {
    //   onBlurOdometer(value);
    // }
    // if (e.target.id === 'odometerType') {
    //   onBlurOdometer(formData.odometer, value);
    // }

    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: boolean ?? value,
    }));
  };

  // const handleToPhotoTab = () => {
  //   photosTabRef.current.click();
  // };

  const onChangeVIN = e => {
    if (e.target.value !== '') {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (!e.target.value.match(regEx)) return;
      if (e.target.value.includes(' ')) return;
      if (e.target.value.includes('I')) return;
      if (e.target.value.includes('i')) return;
      if (e.target.value.includes('O')) return;
      if (e.target.value.includes('o')) return;
      if (e.target.value.includes('Q')) return;
      if (e.target.value.includes('q')) return;
      if (e.target.value.length > 17) return;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const insertRandomVIN = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    setFormData(prevState => ({
      ...prevState,
      vin: CARS.RANDOM_VINS[index],
    }));
  };

  const onClickDecodeVIN = async (vin, odometer) => {
    setDecoding(true);

    try {
      const decodeResponse = await decodeVIN(vin);
      // console.log('response from decode', decodeResponse);

      if (!decodeResponse.success) {
        toast({
          title: t('vinDecodeFailPleaseTryAgain'),
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.status === 'NOT_FOUND') {
        toast({
          title: t('vinDecodeFailPleaseTryAgain') + decodeResponse.message,
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.data.years) {
        toast({
          title: t('vinDecodeFailPleaseTryAgain'),
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      const title = `${decodeResponse.data.years[0]?.year} ${decodeResponse.data.make?.name} ${decodeResponse.data.model?.name}`;

      let vehicleStyle = '';
      if (decodeResponse.data.years[0]?.styles) {
        if (decodeResponse.data.years[0].styles.length === 1) {
          vehicleStyle = decodeResponse.data.years[0].styles[0].name;
        }
      }
      setFormData(prevState => ({
        ...prevState,
        fullVehicle: decodeResponse.data,
        year: decodeResponse.data.years[0]?.year,
        make: decodeResponse.data.make?.name,
        model: decodeResponse.data.model?.name,
        title,
        vin,
        odometer: odometer ? odometer : '',
        colorExterior: '',
        colorInterior: '',
        style: vehicleStyle,
      }));
      toast({
        title: 'VIN Decoded!',
        status: 'success',
        isClosable: true,
      });
      setDecoding(false);
    } catch (e) {
       console.log(e);
      toast({
        title: t('vinDecodeFailPleaseTryAgain'),
        status: 'error',
        isClosable: true,
      });
      setDecoding(false);
    }
  };

  let decodeIsDisabled = true;
  if (formData?.vin.length === 17) {
    decodeIsDisabled = false;
  }

  let missingItems = [];
  let detailsMissingItems = [];
  let listingMissingItems = [];
  let descriptionIsComplete = true;
  let photosAreComplete = true;
  let listingIsComplete = true;
  if (!formData.year) {
    detailsMissingItems.push(t('missingVehicleYear'));
    descriptionIsComplete = false;
  }
  if (!formData.make) {
    detailsMissingItems.push(t('missingVehicleMake'));
    descriptionIsComplete = false;
  }
  if (!formData.model) {
    detailsMissingItems.push(t('missingVehicleModel'));
    descriptionIsComplete = false;
  }
  if (!formData.style) {
    detailsMissingItems.push(t('missingVehicleStyle'));
    descriptionIsComplete = false;
  }
  if (!formData.province) {
    detailsMissingItems.push(t('missingProvince'));
    descriptionIsComplete = false;
  }
  if (!formData.odometer) {
    detailsMissingItems.push(t('missingOdometerReading'));
    descriptionIsComplete = false;
  }
  if (!formData.title) {
    detailsMissingItems.push(t('missingListingTitle'));
    descriptionIsComplete = false;
  }

  if (!formData.listingProfilePhoto) {
    detailsMissingItems.push(t('missingProfilePhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleOdometerPhoto) {
    detailsMissingItems.push(t('missingDashboardOdometerPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleLicensePhoto) {
    detailsMissingItems.push(t('missingLicensePhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleVINPhoto) {
    detailsMissingItems.push(t('missingVINPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleRegistrationPhoto) {
    detailsMissingItems.push(t('missingRegistrationPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleInsurancePhoto) {
    detailsMissingItems.push(t('missingInsurancePhoto'));
    photosAreComplete = false;
  }
  if (!formData.askingPrice) {
    listingMissingItems.push(t('missingAskingPrice'));
    listingIsComplete = false;
  }
  if (!descriptionIsComplete) {
    listingIsComplete = false;
  }
  if (!photosAreComplete) {
    listingIsComplete = false;
  }

  let editIsDisabled = false;
  if (listing.status === LISTING.STATUS.COMPLETED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNING) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_FUNDING) editIsDisabled = true;

  const renderDescription = () => {
    if (decoding) {
      return (
        <Box minHeight="80vh">
          <Center m={10} p={10}>
            <Spinner size="xl" color="primary.500" />
          </Center>
        </Box>
      );
    }

    return (
      <Box minHeight="80vh">
        {editIsDisabled && (
          <Center>
            <Text color="primary.500">
              {t('atThisStageYouCannotEditThisListing')}
            </Text>
          </Center>
        )}

        {!formData.fullVehicle && (
          <>
            <Heading color="primary.500" textAlign="center" size="lg">
              {t('qualifyYourVehicle')}
            </Heading>
            <Center textAlign="center" mt={5}>
              {t('enterYourVehicleVIN')}
            </Center>
            <Center textAlign="center" my={5}>
              {t('theVINCanBeFoundInTheDoorFrame')}
            </Center>
            <Center>
              <Image
                src={vinlocation}
                alt="Logo"
                // boxSize="40px"
                width="70%"
                maxWidth="400px"
                objectFit="cover"
              />
            </Center>

            <FormControl>
              <Stack direction="row" align="center" mt={5}>
                <FormLabel m={0}>
                  {t('vin')}
                  {!formData.vin && <span style={{ color: 'red' }}> *</span>}
                </FormLabel>
              </Stack>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChangeVIN}
                disabled={formData.fullVehicle ? true : false}
              />
            </FormControl>
          </>
        )}
        {!formData.make && !formData.model && (
          <Button
            onClick={() => onClickDecodeVIN(formData.vin)}
            colorScheme="blue"
            w="full"
            mt={5}
            disabled={decodeIsDisabled}
          >
            {t('decodeCapital')}
          </Button>
        )}

        {!formData.fullVehicle && (
          <>
            {isDev && !formData.vin && (
              <Button
                onClick={insertRandomVIN}
                mt={10}
                size="sm"
                variant="link"
                color="lightgray"
              >
                <i>DEV Insert Random VIN</i>
              </Button>
            )}
          </>
        )}

        {formData.fullVehicle && (
          <>
            <FormControl mt="2%">
              <Stack direction="row" w="full">
                <FormLabel m={0} w="50%">
                  {t('year')}
                </FormLabel>
                <FormLabel m={0}>{t('make')}</FormLabel>
              </Stack>
              <InputGroup>
                <Input
                  id="year"
                  // type="text"
                  value={formData.year}
                  // onChange={onChange}
                  disabled
                />
                <Input
                  id="make"
                  type="text"
                  value={formData.make}
                  onChange={onChange}
                  disabled
                  list="makes-list"
                />
              </InputGroup>
              <FormLabel m={0} w="50%">
                {t('model')}
              </FormLabel>
              <InputGroup>
                <Input
                  id="model"
                  type="text"
                  value={formData.model}
                  onChange={onChange}
                  disabled
                />
              </InputGroup>
            </FormControl>

            <FormControl mt="2%" 
            // color={!formData.style ? 'red' : ''}
            >
              <FormLabel m={0}>
                {t('style')}
                {!formData.style && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                {formData.style && (
                  <Input
                    id="style"
                    placeholder="Or enter manually"
                    type="text"
                    value={formData.style}
                    onChange={onChange}
                    disabled={formData.warrantyPending || formData.warrantyName}
                  />
                )}
                {!formData.style && formData.fullVehicle?.years && (
                  <Select
                    id="style"
                    value={formData.style}
                    onChange={onChange}
                    disabled={formData.warrantyPending || formData.warrantyName}
                    // disabled={
                    //   // !isEditing ||
                    //   editIsDisabled || formData?.verified
                    // }
                  >
                    <option value="">{t('select')}</option>
                    {formData.fullVehicle.years[0].styles.map((style, i) => (
                      <option key={i} value={style.name}>
                        {style.name}
                      </option>
                    ))}
                  </Select>
                )}
              </InputGroup>
            </FormControl>
          </>
        )}

        <br />
        {formData.fullVehicle && (
          <>
            <FormLabel m={0} w="50%">
              {t('vin')}
            </FormLabel>
            <InputGroup>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChange}
                disabled
              />
            </InputGroup>

            <FormControl
              mt={'2%'}
              mb={4}
            >
              <FormLabel m={0}>
                {t('odometer')}
                {!formData.odometer && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                <Input
                  id="odometer"
                  type="number"
                  w="50%"
                  value={formData.odometer}
                  onChange={onChange}
                  // disabled={formData?.verified}
                  disabled={formData.warrantyPending || formData.warrantyName}
                  color={!formData.odometer ? 'red' : ''}
                  // disabled //={!isEditing || editIsDisabled}
                  // onBlur={onBlurOdometer}
                />
                <Input
                  id="odometerType"
                  // type="number"
                  w="50%"
                  value={formData.odometerType}
                  onChange={onChange}
                  disabled
                  // onBlur={onBlurOdometer}
                />
              </InputGroup>
            </FormControl>

            <InputGroup>
              {listing?.province && listing?.province.length === 2 ? (
                <Text as="i">
                  {t('homeProvince')}: {listing?.province.toUpperCase()}
                </Text>
              ) : (
              <Select
                id="province"
                mr={2}
                value={formData?.province}
                onChange={onChange}
                // color={!formData.province ? 'red' : ''}
                disabled={formData.warrantyPending || formData.warrantyName}
              >
                <option value="">
                  {t('select')} {t('homeProvince')}
                </option>
                {allProvinces.map((prov, i) => (
                  <option key={i} value={Object.values(prov)}>
                    {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                    {Object.keys(prov)}
                  </option>
                ))}
              </Select>
              )} 
              {!formData.province && <span style={{ color: 'red' }}> *</span>}
            </InputGroup>
            <br />
            <br />
          </>
        )}

{formData.odometer && formData.odometer > 200000 && (
  <Center mb='14px' textAlign='center'>
    <Text color='red' fontWeight='bold'>Warning: vehicles with over 200,000 km are not eligible for most EEZE warranties</Text>
  </Center>
)}


        {formData.fullVehicle && (
          <>
            {!descriptionIsComplete && (
              <Text m={1} color="red" as="i" size="xs">
                * Required Fields
              </Text>
            )}
            <Button
              onClick={() => {
                onClickSave(formData, true);
                // setTabIndex(tabIndex + 1);
              }}
              mb={5}
              w="full"
              colorScheme="primary"
              size="lg"
              disabled={!descriptionIsComplete}
            >
              {t('nextSection')}
            </Button>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <ButtonGroup my={4} alignItems="center" w="100%">
              <Button
                variant="outline"
                size={'sm'}
                onClick={onClickCancel}
                isLoading={isSavingForm}
                flex={1}
              >
                {t('cancel')}
              </Button>
              <Button
                size={'sm'}
                colorScheme="primary"
                variant="outline"
                flex={1}
                onClick={() => {
                  onClickSave(formData);
                }}
              >
                {t('saveCapital')}
              </Button>
            </ButtonGroup>
            <br />

            <Flex
              justify="space-between"
              direction="row"
              // mb="10px"
            >
              {!listing.warrantyActive &&
              !listing.warrantyPending &&
              !listing.vehicleCarfaxVHR &&
              !listing.vehicleCarfaxVHRPending ? (
                <Button
                  // color="red.400"
                  variant="link"
                  color="grey"
                  w="50%"
                  // px={10}
                  size={'sm'}
                  onClick={onClickDelete}
                  isLoading={isSavingForm}
                >
                  {t('delete')}
                </Button>
              ) : (
                <Text></Text>
              )}
              <Text color="grey">
                {t('created')}:{' '}
                {moment(
                  listing.createdAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY')}
              </Text>
            </Flex>
          </>
        )}
      </Box>
    );
  };
  let notAvailableWarning;

  if (
    listing?.province &&
    !eligibleWarrantyProvinces.includes(listing?.province.toLowerCase())
  ) {
    // neither eligible province
    notAvailableWarning = (
      <>
        <Alert
          status="warning"
          variant="left-accent"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon mr={0} />
          <AlertTitle mt={1} mb={1} fontSize="lg">
            {t('pleaseNote')}
          </AlertTitle>
          <AlertDescription maxWidth="lg">
            {t('forNowPremiumEEZEFeaturesAreOnlyAvailableInONandQC')}
            <br />
            <br />
            {t('thisProvinceListingIsOnlyEligibleForTheFreePlan', {
              province: listing.province.toUpperCase(),
            })}
          </AlertDescription>
        </Alert>
        <Button
          onClick={() => {
            setTabIndex(0);
          }}
          mt={10}
          w="full"
          colorScheme="primary"
          size="lg"
          disabled={!descriptionIsComplete}
        >
          {t('previousSection')}
        </Button>
      </>
    );
  }

  if (loading) {
    return <SpinnerPage />;
  }

  return (
    <>
      <Header back />
      <AppBody>
        {formData.title ? (
          <Heading size="lg" textAlign="center">
            {formData.title}
          </Heading>
        ) : (
          <Heading size="sm" textAlign="center">
            {t('vehicle')}
          </Heading>
        )}

        <Container maxW={'2xl'} p={0} mt={2}>
          <Tabs
            isFitted
            variant="enclosed-colored"
            onChange={handleTabsChange}
            index={tabIndex}
          >
            <TabList mb="1em">
              <Tab>
                {t('details')}
                {!descriptionIsComplete && (
                  <Text ml={1} color="red">
                    *
                  </Text>
                )}
              </Tab>
              {listing.status !== LISTING.STATUS.PRIVATE && (
                <Tab isDisabled={formData?.fullVehicle ? false : true}>
                  {t('listing')}
                  {!listingIsComplete && (
                    <Text ml={1} color="red">
                      *
                    </Text>
                  )}
                </Tab>
              )}

              <Tab isDisabled={descriptionIsComplete ? false : true}>
                {t('warranty')}
              </Tab>
            </TabList>

            <TabPanels p={0} m={0}>
              <TabPanel p={0} m={0}>
                {renderDescription()}
              </TabPanel>

              <TabPanel p={0} m={0}>
                <Box p={0} m={0} minHeight="70vh">
                  {notAvailableWarning ? (
                    notAvailableWarning
                  ) : (
                    <WarrantyPage
                      // hideSplash
                      hideBank={!userData?.stageOneVerified}
                    />
                  )}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </AppBody>
    </>
  );
}

export default EditListingWarranty;
